<template>
  <el-dialog
      v-if="visible"
      title="Change exchange status"
      v-model="visible"
      width="30%"
  >
    <el-select v-model="form.status" placeholder="Please select currency">
      <el-option v-for="(status, index) in statuses" :label="status" :value="index" :key="index"/>
    </el-select>
    <template #footer>
    <span class="dialog-footer">
      <el-button
          type="primary"
          @click="update"
          v-loading="loading.update"
      >
        + Change
      </el-button>
    </span>
    </template>
  </el-dialog>
</template>

<script>
export default {
  name: "ExchangeStatus",
  methods: {
    update() {
      this.loading.update = true;
      this.$api.exchanges
          .update(this.params.exchangeId, {status: this.form.status})
          .then(response => {
            this.visible = false;
            this.$emit('updated', response.data)
          })
          .finally(() => this.loading.update = false)
    },
    show(params) {
      this.params = {
        status: params.status,
        exchangeId: params.exchangeId
      };
      this.form.status = this.statuses.findIndex(status => status === params.status);
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
  data() {
    return {
      form: {
        status: null,
      },
      params: {
        exchangeId: null,
      },
      statuses: [
        'Pending',
        'Kyc Verification',
        'Awaiting payment',
        'Paid',
        'Paid Out',
        'Kyc Verification Rejected',
        'Payment Verification',
        'Expired',
      ],
      visible: false,
      loading: {
        update: false,
      }
    }
  },
}
</script>

<style scoped>

</style>
