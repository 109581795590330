function path(id = null) {
    return id ? 'payment-wallets/' + id : 'payment-wallets';
}

export default $client => ({
    get(page) {
        return $client.get(path(), {
            params: {
                page
            }
        })
    },
    getById(id) {
        return $client.get(path(id));
    },
    store(attributes) {
        const {title, value, description} = attributes;
        return $client.post(path(), {title, value, description});
    },
    update(id, attributes) {
        const {title, value, description} = attributes;
        return $client.patch(path(id), {title, value, description});
    }
});
