export default $client => ({
    get(page) {
        return $client.get('exchanges', {
            params: {
                page
            }
        })
    },
    getById(id) {
        return $client.get('exchanges/' + id);
    },
    update(id, attributes) {
        return $client.patch('exchanges/' + id, attributes);
    },
    details: {
        update(exchangeId, detailId, attributes) {
            return $client.patch(`exchanges/${exchangeId}/details/${detailId}`, attributes);
        }
    }
});
