<template>
  <el-table
      :data="identityVerifications"
      v-loading="loading.fetch"
      style="width: 100%;"
  >
    <el-table-column
        prop="id"
        label="ID"
        width="50"
    />
    <el-table-column
        prop="created_at"
        label="Creation Date">
    </el-table-column>
    <el-table-column
        prop="user.email"
        label="E-Mail"
        min-width="120"
    >
    </el-table-column>
    <el-table-column
        prop="status"
        label="Status"
    >
      <template v-slot="scope">
        <el-button type="text" @click="showChangeStatus(scope.row)" :disabled="scope.row.status === 'Kyc Verification'">
          {{ scope.row.status }}
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <pagination :pagination="pagination" @change="this.fetchIdentityVerifications"/>
  <identity-verification-status ref="identityVerificationStatus" @updated="this.fetchIdentityVerifications()"/>
</template>

<script>
import Pagination from "@/components/Pagination";
import IdentityVerificationStatus from "@/components/IdentityVerification/IdentityVerificationStatus";

export default {
  components: {
    Pagination,
    IdentityVerificationStatus
  },
  created() {
    this.fetchIdentityVerifications();
  },
  methods: {
    fetchIdentityVerifications(page = 1) {
      this.loading.fetch = true;
      this.$api.identity.get(page).then(response => {
        this.identityVerifications = response.data;
        this.pagination = response.meta;
        this.loading.fetch = false;
      });
    },
    showChangeStatus(identity) {
      this.$refs.identityVerificationStatus.show({
        identityId: identity.id,
        status: identity.status,
      });
    },
  },
  data() {
    return {
      identityVerifications: [],
      pagination: {},
      loading: {
        fetch: false,
      },
    }
  }
}
</script>
