<template>
  <el-drawer
      v-if="visible"
      v-model="visible"
      :title="title"
      :before-close="hide"
      direction="rtl"
      size="30%"
  >
    <el-form
        label-position="top"
        label-width="100px"
        ref="form"
        :model="form"
        :rules="rules"
        v-loading="loading.fetch"
    >
      <el-form-item label="Title" prop="title">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="Wallet value" prop="value">
        <el-input v-model="form.value"></el-input>
      </el-form-item>
      <el-form-item label="Additional info for user" prop="description">
        <el-input type="textarea" v-model="form.description"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="validate" v-loading="loading.update">{{ button }}</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
export default {
  name: "PaymentWalletAction",
  methods: {
    show(params) {
      this.params = {
        paymentWalletId: params ? params.paymentWalletId : null,
      }

      if (this.isEdit) {
        this.fetch();
      }

      this.visible = true;
    },
    hide() {
      this.loading.action = false;
      this.form = {
        title: null,
        value: null,
        description: null
      };
      this.visible = false;
    },
    fetch() {
      this.loading.fetch = true;
      this.$api.paymentWallets.getById(this.params.paymentWalletId).then(response => {
        const {title, value, description} = response.data;
        this.form = {title, value, description};

      }).finally(() => {
        this.loading.fetch = false;
      })
    },
    async create() {
      this.$api.paymentWallets.store(this.form).then(response => {
        this.hide();
        this.$emit('created', response.data);
      });
    },
    async edit() {
      this.$api.paymentWallets.update(this.params.paymentWalletId, this.form).then(response => {
        this.hide();
        this.$emit('updated', response.data);
      });
    },
    async validate() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      this.loading.action = true;

      this.isEdit ? this.edit() : this.create();
    }
  },
  computed: {
    isEdit() {
      return !! this.params.paymentWalletId;
    },
    title() {
      if (this.isEdit) {
        return 'Edit payment wallet #' + this.params.paymentWalletId;
      }

      return 'Create payment wallet';
    },
    button() {
      return this.isEdit ? 'Save' : '+ Add';
    },
    rules() {
      return {
        title: [
          {required: true, message: 'Please input title', trigger: 'blur'},
          {min: 3, max: 32, message: 'Length should be 3 to 32', trigger: 'blur'}
        ],
        value: [
          {required: true, message: 'Please input wallet', trigger: 'blur'},
          {min: 10, max: 128, message: 'Length should be 10 to 128', trigger: 'blur'}
        ],
        description: [
          {min: 3, max: 1000, message: 'Length should be 3 to 1000', trigger: 'blur'}
        ],
      };
    }
  },
  data() {
    return {
      visible: false,
      params: {},
      form: {
        title: null,
        value: null,
        description: null,
      },
      loading: {
        fetch: false,
        action: false,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.el-drawer {
  &__title {
    margin-bottom: 0;
  }
}

form {
  padding: 20px 20px 0;
}
</style>
