<template>
  <el-table
      :data="users"
      v-loading="loading"
      style="width: 100%;"
  >
    <el-table-column
        prop="id"
        label="ID"
        width="50"
    />
    <el-table-column
        prop="name"
        label="Name"
    />
    <el-table-column
        prop="email"
        label="E-Mail"
    >
    </el-table-column>
    <el-table-column
        label="Email Verified"
    >
      <template v-slot="scope">
        <el-tag size="small" :type="scope.row.has_verified_email ? 'primary' : 'info'">
          {{ scope.row.has_verified_email ? 'Yes' : 'No' }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
        label="Identity Verified"
    >
      <template v-slot="scope">
        <el-tag size="small" :type="scope.row.has_verified_identity ? 'primary' : 'info'">
          {{ scope.row.has_verified_identity ? 'Yes' : 'No' }}
        </el-tag>
      </template>
    </el-table-column>
    <el-table-column
        prop="created_at"
        label="Created At">
    </el-table-column>
    <el-table-column
        prop="updated_at"
        label="Updated At">
    </el-table-column>
    <el-table-column label="Actions" align="right">
      <template v-slot>
        <el-button
            size="mini"
            @click="edit"
        >
          Edit
        </el-button>
      </template>
    </el-table-column>
  </el-table>
  <pagination :pagination="pagination" @change="this.fetchUsers"/>
</template>

<script>
import Pagination from "@/components/Pagination";

export default {
  components: {
    Pagination
  },
  created() {
    this.fetchUsers();
  },
  methods: {
    fetchUsers(page = 1) {
      this.loading = true;
      this.$api.users.get(page).then(response => {
        this.users = response.data;
        this.pagination = response.meta;
        this.loading = false;
      });
    },
    edit(user) {
      console.log(user)
    }
  },
  data() {
    return {
      users: [],
      pagination: {},
      loading: false,
    }
  }
}
</script>
