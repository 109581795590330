<template>
  <el-aside>
    <el-menu style="height: 100vh" :router="true" :default-active="path">
      <template #default>
        <div class="sidebar__header">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="6" cy="6" r="6" fill="#6C56CA"/>
            <circle opacity="0.48" cx="26" cy="6" r="6" fill="#4A454B"/>
            <circle cx="6" cy="26" r="6" fill="#7D92EE"/>
            <circle cx="26" cy="26" r="6" fill="#4A454B"/>
          </svg>
          <span>CryptoShop Panel</span>
        </div>
        <side-bar-item v-for="route in routes" :route="route" :key="route.name"/>
      </template>
    </el-menu>
  </el-aside>
</template>

<script>
import SideBarItem from "@/components/SidebarItem";

export default {
  name: "Sidebar",
  components: {
    SideBarItem,
  },
  computed: {
    path() {
      return this.$route.name;
    },
    routes() {
      return this.$router.options.routes.filter(route => route.sidebar);
    }
  }
}
</script>

<style lang="scss">
.sidebar__header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  padding: 20px;

  & span {
    margin-left: 1rem;
  }
}
</style>
