<template>
  <div>
    <el-submenu v-if="route.children" :index="route.path" :key="route.name">
      <template #title>
        <i :class="route.sidebar.icon"></i>
        <span>{{ route.sidebar.title }}</span>
      </template>
      <el-menu-item-group v-for="children in route.children" :key="children.name">
        <el-menu-item :index="route.path + '/' + children.path">{{ children.sidebar.title }}</el-menu-item>
      </el-menu-item-group>
    </el-submenu>
    <el-menu-item v-else :index="route.path" :key="route.name">
      <i :class="route.sidebar.icon"></i>
      <span>{{ route.sidebar.title }}</span>
    </el-menu-item>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
  props: {
    route: {
      type: Object,
    }
  },
  computed: {
    path() {
      return this.$route.name;
    },
    routes() {
      return this.$router.options.routes.filter(route => route.sidebar);
    }
  }
}
</script>


