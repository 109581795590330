<template>
  <el-card class="box-card" v-loading="loading.fetch">
    <template #header>
      <div class="card-header">
        <span>Payment gateways</span>
      </div>
    </template>
    <el-form label-position="top" :model="form">
      <el-form-item label="Fiat to crypto">
        <el-select v-model="form.fiat_to_crypto" placeholder="Please select gateway">
          <el-option label="Heropayments" value="heropayments"/>
          <el-option label="Coinpayments" value="coinpayments"/>
          <el-option label="Wallex" value="wallex"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Crypto to fiat">
        <el-select v-model="form.crypto_to_fiat" placeholder="Please select gateway">
          <el-option label="Heropayments" value="heropayments"/>
          <el-option label="Easytransfer" value="easytransfer"/>
          <el-option label="Wallex" value="wallex"/>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="Crypto to crypto">
        <el-select v-model="form.crypto_to_crypto" placeholder="Please select gateway">
          <el-option label="Heropayments" value="heropayments"/>
          <el-option label="Coinpayments" value="coinpayments"/>
          <el-option label="Wallex" value="wallex"/>
        </el-select>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="submit" v-loading="loading.update">Update</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: 'PaymentGateways',
  data: () => ({
    settings: {},
    form: {
      fiat_to_crypto: '',
      crypto_to_fiat: '',
      crypto_to_crypto: '',
    },
    loading: {
      fetch: false,
      update: false,
    },
  }),
  created() {
    this.fetchGatewaySettings();
  },
  methods: {
    fetchGatewaySettings() {
      this.loading.fetch = true;
      this.$api.settings.gateway.get()
        .then(response => {
          this.settings = response.data;
          this.form = {
            fiat_to_crypto: this.settings.fiat_to_crypto,
            crypto_to_fiat: this.settings.crypto_to_fiat,
            crypto_to_crypto: this.settings.crypto_to_crypto,
          }
        })
        .finally(() => this.loading.fetch = false);
    },
    submit() {
      this.loading.update = true;
      this.$api.settings.gateway.update(this.form)
        .then(response => this.settings = response.data)
        .finally(() => this.loading.update = false);
    }
  },
}
</script>
