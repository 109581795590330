function path(id = null) {
    return id ? 'coins/' + id : 'coins';
}

export default $client => ({
    get(page = null) {
        return $client.get(path(), {
            params: {
                page,
            }
        })
    },
    getById(id) {
        return $client.get(path(id));
    },
    getFiat() {
        return $client.get(path(), {
            params: {
                fiat: true,
            }
        })
    },
    store(attributes) {
        const {title, slug, icon, fiat, currencyId, paymentWalletId} = attributes;

        const formData = new FormData();

        formData.append('title', title);
        formData.append('slug', slug);
        formData.append('icon', icon);
        formData.append('fiat', +fiat);
        formData.append('currency_id', currencyId);
        formData.append('payment_wallet_id', paymentWalletId);

        return $client.post(path(), formData);
    },
    update(id, attributes) {
        const {title, slug, icon, fiat, currencyId, paymentWalletId} = attributes;

        const formData = new FormData();

        formData.append('title', title);
        formData.append('slug', slug);

        if (icon instanceof File || icon === null) {
            formData.append('icon', icon);
        }

        formData.append('fiat', +fiat);
        formData.append('currency_id', currencyId);
        formData.append('payment_wallet_id', paymentWalletId);

        formData.append('_method', 'PATCH');

        return $client.post(path(id), formData);
    },
    destroy(id) {
        return $client.delete(path(id));
    }
});
