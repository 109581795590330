<template>
  <el-drawer
      v-if="visible"
      v-model="visible"
      :title="title"
      :before-close="hide"
      direction="rtl"
      size="30%"
  >
    <el-form
        label-position="top"
        label-width="100px"
        ref="form"
        :model="form"
        :rules="rules"
        v-loading="loading.fetch"
    >
      <el-form-item label="Title" prop="title">
        <el-input v-model="form.title"></el-input>
      </el-form-item>
      <el-form-item label="Slug" prop="slug">
        <el-input v-model="form.slug"></el-input>
      </el-form-item>
      <el-form-item label="Currency">
        <el-select v-model="form.currencyId" placeholder="Please select currency">
          <el-option v-for="currency in currencies" :label="currency.iso" :value="currency.id" :key="currency.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Payment wallet">
        <el-select v-model="form.paymentWalletId" placeholder="Please select wallet">
          <el-option v-for="wallet in paymentWallets" :label="wallet.title" :value="wallet.id" :key="wallet.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Icon" prop="icon">
        <upload-file @input="inputUploadFile" :file="{name: coin.icon, url: coin.icon_url}"/>
      </el-form-item>
      <el-form-item label="Is fiat?">
        <el-switch v-model="form.fiat">
        </el-switch>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="validate" v-loading="loading.update">{{ button }}</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>
import UploadFile from "@/components/UploadFile";

export default {
  name: "CoinAction",
  components: {UploadFile},
  methods: {
    show(params) {
      if (this.currencies.length < 1) {
        this.fetchCurrency();
      }

      if (this.paymentWallets.length < 1) {
        this.fetchPaymentWallets();
      }

      this.params = {
        coinId: params ? params.coinId : null,
      }

      if (this.isEdit) {
        this.fetch();
      }

      this.visible = true;
    },
    hide() {
      this.loading.action = false;
      this.coin = {};
      this.form = {
        title: null,
        slug: null,
        icon: null,
        iconUrl: null,
        fiat: null,
        currencyId: null,
        paymentWalletId: null,
      };
      this.visible = false;
    },
    inputUploadFile(file) {
      this.form.icon = file;
    },
    fetch() {
      this.loading.fetch = true;
      this.$api.coins.getById(this.params.coinId).then(response => {
        const {title, slug, icon, icon_url, fiat, currency_id, payment_wallet_id} = response.data;
        this.coin = response.data;
        this.form = {
          title, slug, icon, icon_url, fiat,
          currencyId: currency_id,
          paymentWalletId: payment_wallet_id,
        };
      }).finally(() => {
        this.loading.fetch = false;
      })
    },
    fetchCurrency() {
      this.$api.currencies.get().then(response => this.currencies = response.data);
    },
    fetchPaymentWallets() {
      this.$api.paymentWallets.get().then(response => this.paymentWallets = response.data);
    },
    async create() {
      this.$api.coins.store(this.form).then(response => {
        this.hide();
        this.$emit('created', response.data);
      });
    },
    async edit() {
      this.$api.coins.update(this.params.coinId, this.form).then(response => {
        this.hide();
        this.$emit('updated', response.data);
      });
    },
    async validate() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      this.loading.action = true;

      this.isEdit ? this.edit() : this.create();
    }
  },
  computed: {
    isEdit() {
      return !! this.params.coinId;
    },
    title() {
      if (this.isEdit) {
        return 'Edit coin #' + this.params.coinId;
      }

      return 'Create coin';
    },
    button() {
      return this.isEdit ? 'Save' : '+ Add';
    },
    rules() {
      return {
        title: [
          {required: true, message: 'Please input title', trigger: 'blur'},
          {min: 3, max: 32, message: 'Length should be 3 to 32', trigger: 'blur'}
        ],
        slug: [
          {required: true, message: 'Please input slug', trigger: 'blur'},
          {min: 3, max: 32, message: 'Length should be 10 to 128', trigger: 'blur'}
        ],
        currencyId: [
          {required: true, message: 'Please select currency', trigger: 'blur'},
        ]
      };
    }
  },
  data() {
    return {
      visible: false,
      params: {},
      coin: {},
      currencies: [],
      paymentWallets: [],
      form: {
        title: null,
        slug: null,
        icon: null,
        fiat: null,
        currencyId: null,
        paymentWalletId: null,
      },
      loading: {
        fetch: false,
        action: false,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.el-drawer {
  &__title {
    margin-bottom: 0;
  }
}

form {
  padding: 20px 20px 0;
}
</style>
