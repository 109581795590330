<template>
  <el-table
      :data="exchanges"
      v-loading="loading.fetch"
      style="width: 100%;"
  >
    <el-table-column
        prop="id"
        label="ID"
        width="50"
    />
    <el-table-column
        prop="created_at"
        label="Creation Date"
        min-width="90"
    >
    </el-table-column>
    <el-table-column
        prop="user.email"
        label="E-Mail"
        min-width="120"
    >
    </el-table-column>
    <el-table-column
        label="Currency"
        width="200"
    >
      <template v-slot="scope">
        <el-button :type="scope.row.coin_pair.base.fiat ? 'info' : 'primary'" size="mini" circle>
          <img style="height: 18px" :src=" scope.row.coin_pair.base.icon_url"/>
        </el-button>
        {{ scope.row.coin_pair.base.title }}
        <i class="el-icon-right"/>
        {{ scope.row.coin_pair.quote.title }}
        <el-button :type="scope.row.coin_pair.quote.fiat ? 'info' : 'primary'" size="mini" circle>
          <img style="height: 18px" :src=" scope.row.coin_pair.quote.icon_url"/>
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
        prop="details.give"
        label="Give"
        min-width="80"
    />
    <el-table-column
        prop="details.receive"
        label="Receive"
        min-width="80"
    />
    <el-table-column
        label="TxHash"
        min-width="220"
    >
      <template v-slot="scope">
        <el-button v-if="!scope.row.hide_admin_hash" type="text" @click="showAddTxHash(scope.row)">
          {{ scope.row.details && scope.row.details.txid ? scope.row.details.txid : 'Add TxHash' }}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
        label="Risk"
        min-width="50"
    >
      <template v-slot="scope">
        <span>{{ scope.row.transaction_verification ? (scope.row.transaction_verification.risk + '%') : 'None' }}</span>
      </template>
    </el-table-column>
    <el-table-column
        prop="status"
        label="Status"
        min-width="100"
    >
      <template v-slot="scope">
        <el-button type="text" @click="showChangeStatus(scope.row)" :disabled="scope.row.status === 'Kyc Verification'">
          {{ scope.row.status }}
        </el-button>
      </template>
    </el-table-column>
    <el-table-column label="Actions" align="center">
      <template v-slot="scope">
        <el-button type="info" icon="el-icon-view" @click="showExchangeDrawer(scope.row)" circle/>
        <el-button type="danger" icon="el-icon-delete" :disabled="true" circle></el-button>
      </template>
    </el-table-column>
  </el-table>
  <pagination :pagination="pagination" @change="this.fetchExchanges"/>
  <add-tx-hash ref="addTxHash" @updated="this.fetchExchanges()"/>
  <exchange-status ref="changeStatus" @updated="this.fetchExchanges()"/>
  <exchange-drawer ref="exchangeDrawer"/>
</template>

<script>
import Pagination from "@/components/Pagination";
import ExchangeDrawer from "@/views/Exchange/ExchangeDrawer";
import AddTxHash from "@/components/Exchange/AddTxHash";
import ExchangeStatus from "@/components/Exchange/ExchangeStatus";

export default {
  components: {
    ExchangeStatus,
    AddTxHash,
    ExchangeDrawer,
    Pagination
  },
  created() {
    this.fetchExchanges()
  },
  methods: {
    fetchExchanges(page = 1) {
      this.loading.fetch = true;
      this.$api.exchanges.get(page).then(response => {
        this.exchanges = response.data;
        this.pagination = response.meta;
        this.loading.fetch = false;
      });
    },
    showAddTxHash(exchange) {
      this.$refs.addTxHash.show({
        detailId: exchange.details.id,
        exchangeId: exchange.id,
        txId: exchange.details.txid,
        isFiat: exchange.coin_pair?.base.fiat,
      })
    },
    showChangeStatus(exchange) {
      this.$refs.changeStatus.show({
        exchangeId: exchange.id,
        status: exchange.status,
      });
    },
    showExchangeDrawer(exchange) {
      this.$refs.exchangeDrawer.show({
        exchangeId: exchange.id,
      })
    },
  },
  data() {
    return {
      exchanges: [],
      pagination: {},
      checkIntervalId: null,
      loading: {
        fetch: false,
      },
    }
  }
}
</script>
