<template>
  <div>
    Dashboard
  </div>
</template>

<script>

export default {
  name: 'Dashboard',
}
</script>
