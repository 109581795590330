<template>
  <el-card class="box-card" v-loading="loading.fetch">
    <template #header>
      <div class="card-header">
        <span>Exchange Settings</span>
      </div>
    </template>
    <el-form label-position="top" :model="form">
      <el-form-item label="Stock Exchange">
        <el-select v-model="form.stockExchangeId" placeholder="Please select setock exchange">
          <el-option
              v-for="stockExchange in stockExchanges"
              :label="stockExchange.title"
              :value="stockExchange.id"
              :key="stockExchange.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="Exchange fee">
        <el-input v-model="form.fee" placeholder="Enter percentage"></el-input>
      </el-form-item>
      <el-form-item label="Identity verification limit amount">
        <el-input v-model="form.identityLimitAmount" placeholder="Enter amount"></el-input>
      </el-form-item>
      <el-form-item label="Identity verification limit coin">
        <el-select v-model="form.identityLimitCoinId" placeholder="Please select currency">
          <el-option v-for="coin in coins" :label="coin.title" :value="coin.id" :key="coin.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Tx hash check manual mode">
        <el-select v-model="form.txHashCheckManualMode" placeholder="Please select mode">
          <el-option :value="false"/>
          <el-option :value="true"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Payment manual mode">  
        <el-select v-model="form.manualPaymentMode" placeholder="Please select payment mode">
          <el-option :value="false"/>
          <el-option :value="true"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="update" v-loading="loading.update">Update</el-button>
      </el-form-item>
    </el-form>
  </el-card>
</template>

<script>
export default {
  name: "ExchangeSettings",
  created() {
    this.fetchCoins();
    this.fetchStockExchanges();
    this.fetchExchangeSettings();
  },
  methods: {
    fetchCoins() {
      this.$api.coins.getFiat().then(response => {
        this.coins = response.data;
      })
    },
    fetchExchangeSettings() {
      this.loading.fetch = true;
      this.$api.settings.exchange.get().then(response => {
        this.settings = response.data;
        this.form = {
          fee: this.settings.fee,
          identityLimitAmount: this.settings.identity_limit_amount,
          identityLimitCoinId: this.settings.identity_limit_coin_id,
          stockExchangeId: this.settings.stock_exchange_id,
          txHashCheckManualMode: this.settings.tx_hash_check_manual_mode,
          manualPaymentMode: this.settings.manual_payment_mode,
        }
      }).finally(() => this.loading.fetch = false);
    },
    fetchStockExchanges() {
      this.$api.stockExchanges.get().then(({data}) => this.stockExchanges = data);
    },
    update() {
      this.loading.update = true;
      this.$api.settings.exchange.update(this.form)
          .then(response => this.settings = response.data)
          .finally(() => this.loading.update = false);
    }
  },
  data() {
    return {
      settings: {},
      coins: [],
      stockExchanges: [],
      loading: {
        fetch: false,
        update: false,
      },
      form: {
        fee: null,
        identityLimitAmount: null,
        identityLimitCoinId: null,
        stockExchangeId: null,
        txHashCheckManualMode: false,
      }
    }
  }
}
</script>

<style scoped>

</style>
