<template>
  <el-drawer
      v-model="visible"
      :title="'Exchange #' + exchangeId"
      direction="rtl"
      size="30%"
  >
    <div v-if="exchange.id" class="exchange__items" v-loading="loading.fetch">
      <el-divider content-position="left">Base</el-divider>
      <div class="exchange__item">
        <label>ID:</label>
        <span>{{ exchange.id }}</span>
      </div>
      <div class="exchange__item">
        <label>Hash:</label>
        <span>{{ exchange.hash }}</span>
      </div>
      <div class="exchange__item">
        <label>Status:</label>
        <span>{{ exchange.status }}</span>
      </div>
      <div class="exchange__item">
        <label>Created At:</label>
        <span>{{ exchange.created_at }}</span>
      </div>
      <div class="exchange__item">
        <label>Updated At:</label>
        <span>{{ exchange.updated_at }}</span>
      </div>
      <el-divider content-position="left">Details</el-divider>
      <div class="exchange__item">
        <label>Currency:</label>
        <span>{{ exchange.coin_pair.base.title }} -> {{ exchange.coin_pair.quote.title }}</span>
      </div>
      <div class="exchange__item">
        <label>Give:</label>
        <span>{{ exchange.details.give }}</span>
      </div>
      <div class="exchange__item">
        <label>Receive:</label>
        <span>{{ exchange.details.receive }}</span>
      </div>
      <div class="exchange__item">
        <label>Income:</label>
        <span>{{ exchange.details.income }}</span>
      </div>
      <div class="exchange__item">
        <label>Rate:</label>
        <span>{{ exchange.details.rate }}</span>
      </div>
      <el-divider content-position="left">Fields</el-divider>
      <div v-for="(field, index) in exchange.fields" :key="index" class="exchange__item">
        <label>{{ field.label }}:</label>
        <span>{{ field.value }}</span>
      </div>
      <el-divider content-position="left">Other</el-divider>
      <div v-if="exchange.payment_wallet" class="exchange__item">
        <label>Our Payment Wallet:</label>
        <span>{{ exchange.payment_wallet.value }}</span>
      </div>
      <div v-if="exchange.code" class="exchange__item">
        <label>Comment for payment:</label>
        <span>{{ exchange.code }}</span>
      </div>
    </div>
  </el-drawer>
</template>

<script>
export default {
  name: "ExchangeDrawer",
  methods: {
    isLoading() {
      return this.loading.fetch;
    },
    fetchExchange() {
      this.loading.fetch = true;
      this.$api.exchanges.getById(this.exchangeId).then(response => {
        this.exchange = response.data;
      }).finally(() => {
        this.loading.fetch = false;
      })
    },
    show(params) {
      this.params = {exchangeId: params.exchangeId};
      this.visible = true;
      this.fetchExchange();
    },
    hide() {
      this.visible = false;
    },
  },
  computed: {
    exchangeId() {
      return this.params.exchangeId;
    }
  },
  data() {
    return {
      exchange: {},
      visible: false,
      params: {},
      loading: {
        fetch: false,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.el-drawer__title {
  margin-bottom: 0;
}

.exchange__items {
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0;

  & .exchange__item {
    display: flex;
    justify-content: space-between;

    & label {
      color: #7D92EE;
      font-weight: bold;
    }

    & span {
      color: #252528;
      font-weight: 300;
    }

    margin-bottom: 0.43rem;
  }
}
</style>
