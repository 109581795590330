const PATH = 'settings';

export default $client => ({
    exchange: {
        get() {
            return $client.get(PATH + '/exchange')
        },
        update(settings) {
            return $client.put(PATH + '/exchange', {
                identity_limit_amount: settings.identityLimitAmount,
                identity_limit_coin_id: settings.identityLimitCoinId,
                stock_exchange_id: settings.stockExchangeId,
                tx_hash_check_manual_mode: settings.txHashCheckManualMode,
                manual_payment_mode: settings.manualPaymentMode,
            })
        },
        updatePassword(password) {
            return $client.put(PATH + '/password', {
                password: password,
            })
        }
    },
    gateway: {
        get() {
            return $client.get(PATH + '/gateway')
        },
        update(settings) {
            return $client.put(PATH + '/gateway', settings)
        },
    },
});
