<template>
  <el-table
      :data="paymentWallets"
      v-loading="loading.fetch"
      style="width: 100%;"
  >
    <el-table-column
        prop="id"
        label="ID"
        width="50"
    />
    <el-table-column
        prop="created_at"
        label="Creation Date">
    </el-table-column>
    <el-table-column
        prop="title"
        label="Title"
        min-width="120"
    />
    <el-table-column
        prop="value"
        label="Value"
    />
    <el-table-column label="Actions" align="center">
      <template #header>
        <el-button
            type="primary"
            @click="showPaymentWalletCreate"
        >
          + Add
        </el-button>
      </template>
      <template v-slot="scope">
        <el-button type="primary" icon="el-icon-edit" @click="showPaymentWalletEdit(scope.row)" circle/>
        <el-button type="danger" icon="el-icon-delete" :disabled="true" circle></el-button>
      </template>
    </el-table-column>
  </el-table>
  <pagination :pagination="pagination" @change="fetchPaymentWallets"/>
  <payment-wallet-action
      ref="paymentWalletAction"
      @created="fetchPaymentWallets"
      @updated="fetchPaymentWallets"
  />
</template>

<script>
import Pagination from "@/components/Pagination";
import PaymentWalletAction from "@/views/PaymentWallet/PaymentWalletAction";

export default {
  name: "PaymentWallet",
  components: {
    PaymentWalletAction,
    Pagination,
  },
  created() {
    this.fetchPaymentWallets();
  },
  methods: {
    fetchPaymentWallets(page = 1) {
      this.loading.fetch = true;
      this.$api.paymentWallets.get(page).then(response => {
        this.paymentWallets = response.data;
        this.pagination = response.meta;
        this.loading.fetch = false;
      });
    },
    showPaymentWalletCreate() {
      this.$refs.paymentWalletAction.show();
    },
    showPaymentWalletEdit(paymentWallet) {
      this.$refs.paymentWalletAction.show({
        paymentWalletId: paymentWallet.id,
      });
    }
  },
  data() {
    return {
      paymentWallets: [],
      pagination: {},
      loading: {
        fetch: false,
      },
    }
  }
}
</script>
