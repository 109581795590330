function path(id = null) {
    return id ? 'coin-pairs/' + id : 'coin-pairs';
}

export default $client => ({
    get(page) {
        return $client.get(path(), {
            params: {
                page
            }
        })
    },
    getById(id) {
        return $client.get(path(id));
    },
    store(attributes) {
        return $client.post(path(), {
            base_id: attributes.baseId,
            quote_id: attributes.quoteId,
            stock_exchange_id: attributes.stockExchangeId,
            commission_percentage: + attributes.commissionPercentage,
        })
    },
    update(id, attributes) {
        return $client.patch(path(id), {
            base_id: attributes.baseId,
            quote_id: attributes.quoteId,
            stock_exchange_id: attributes.stockExchangeId,
            commission_percentage: + attributes.commissionPercentage,
        })
    },
    destroy(id) {
        return $client.delete(path(id));
    }
});
