import client from "./client";

import Currencies from './endpoints/currencies';
import Coins from './endpoints/coins';
import CoinPairs from './endpoints/coin-pairs';
import Exchanges from './endpoints/exchanges';
import Identity from './endpoints/identity';
import Users from './endpoints/users';
import PaymentWallets from './endpoints/payment-wallets';
import Settings from './endpoints/settings';
import Feedback from './endpoints/feedback';
import StockExchanges from './endpoints/stock-exchanges';
import Transactions from './endpoints/transactions';
import Auth from './endpoints/auth';

class Api {
  constructor() {
    this.client = client;
    this._loadEndPoints();
  }

  _loadEndPoints() {
    this.currencies = Currencies(this.client);
    this.coins = Coins(this.client);
    this.coinPairs = CoinPairs(this.client);
    this.exchanges = Exchanges(this.client);
    this.identity = Identity(this.client);
    this.users = Users(this.client);
    this.paymentWallets = PaymentWallets(this.client);
    this.settings = Settings(this.client);
    this.feedback = Feedback(this.client);
    this.stockExchanges = StockExchanges(this.client);
    this.transactions = Transactions(this.client);
    this.auth = Auth(this.client);
  }
}

export default Api;
