<template>
  <el-pagination
      background
      layout="prev, pager, next"
      :total="pagination.total"
      :page-count="pagination.last_page"
      :page-size="pagination.per_page"
      :current-page="pagination.current_page"
      @current-change="currentChange"
  >
  </el-pagination>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    pagination: {
      type: Object,
      required: true,
    }
  },
  methods: {
    currentChange(page) {
      this.$emit('change', page)
    }
  }
}
</script>

<style scoped>
.el-pagination {
  float: right;
  margin-top: 1rem;
}
</style>
