<template>
  <el-drawer
      v-if="visible"
      v-model="visible"
      :title="title"
      :before-close="hide"
      direction="rtl"
      size="30%"
  >
    <el-form
        label-position="top"
        label-width="100px"
        ref="form"
        :model="form"
        :rules="rules"
        v-loading="loading.fetch"
    >
      <el-form-item label="Base Coin" prop="baseId">
        <el-select v-model="form.baseId" placeholder="Please select coin">
          <el-option v-for="coin in coins" :label="coin.title" :value="coin.id" :key="coin.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Quote Coin" prop="quoteId">
        <el-select v-model="form.quoteId" placeholder="Please select coin">
          <el-option v-for="coin in coins" :label="coin.title" :value="coin.id" :key="coin.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="Stock Exchange" prop="stockExchangeId">
        <el-select v-model="form.stockExchangeId" placeholder="Please select stock exchange">
          <el-option v-for="stockExchange in stockExchanges"
                      :label="stockExchange.title"
                      :value="stockExchange.id"
                      :key="stockExchange.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item class="number" label="Commission percentage">
        <el-input v-model="form.commissionPercentage" placeholder="Enter value"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="validate" v-loading="loading.update">{{ button }}</el-button>
      </el-form-item>
    </el-form>
  </el-drawer>
</template>

<script>

export default {
  name: "CoinPairAction",
  methods: {
    show(params) {
      if (this.coins.length < 1) {
        this.fetchCoins();
      }

      if (this.stockExchanges.length < 1) {
        this.fetchStockExchanges();
      }

      this.params = {
        id: params ? params.id : null,
      }

      if (this.isEdit) {
        this.fetch();
      }

      this.visible = true;
    },
    hide() {
      this.loading.action = false;
      this.form = {
        baseId: null,
        quoteId: null,
        commissionPercentage: null,
      };
      this.visible = false;
    },
    fetch() {
      this.loading.fetch = true;
      this.$api.coinPairs.getById(this.params.id).then(response => {
        const {base_id, quote_id, stock_exchange_id, commission_percentage} = response.data;
        this.form = {
          baseId: base_id,
          quoteId: quote_id,
          stockExchange: stock_exchange_id,
          commissionPercentage: commission_percentage,
        };
      }).finally(() => {
        this.loading.fetch = false;
      })
    },
    fetchCoins() {
      this.$api.coins.get().then(({data}) => this.coins = data);
    },
    fetchStockExchanges() {
      this.$api.stockExchanges.get().then(({data}) => this.stockExchanges = data);
    },
    async create() {
      this.$api.coinPairs.store(this.form).then(response => {
        this.hide();
        this.$emit('created', response.data);
      });
    },
    async edit() {
      this.$api.coinPairs.update(this.params.id, this.form).then(response => {
        this.hide();
        this.$emit('updated', response.data);
      });
    },
    async validate() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      this.loading.action = true;

      this.isEdit ? this.edit() : this.create();
    }
  },
  computed: {
    isEdit() {
      return !! this.params.id;
    },
    title() {
      if (this.isEdit) {
        return 'Edit coin pair #' + this.params.id;
      }

      return 'Create coin pair';
    },
    button() {
      return this.isEdit ? 'Save' : '+ Add';
    },
    rules() {
      return {
        baseId: [
          {required: true, message: 'Please select coin', trigger: 'blur'},
        ],
        quoteId: [
          {required: true, message: 'Please select coin', trigger: 'blur'},
        ]
      };
    }
  },
  data() {
    return {
      visible: false,
      params: {},
      coins: [],
      stockExchanges: [],
      form: {
        baseId: null,
        quoteId: null,
        commissionPercentage: null,
      },
      loading: {
        fetch: false,
        action: false,
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.el-drawer {
  &__title {
    margin-bottom: 0;
  }
}

form {
  padding: 20px 20px 0;
}
</style>
