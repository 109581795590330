<template>
  <el-dialog
      v-if="visible"
      title="Add TxHash"
      v-model="visible"
      width="30%"
  >
    <el-form-item>
      <el-input placeholder="Enter TxHash" v-model="form.txid"/>
    </el-form-item>
    <el-form-item>
      <el-checkbox v-model="aml.enabled" :disabled="params.isFiat">AML Verify</el-checkbox>
    </el-form-item>
    <el-alert
        v-if="risk"
        :title="'Risk score:' + risk + '%'"
        type="info"
        show-icon
    >
    </el-alert>
    <template #footer>
    <span class="dialog-footer">
        <el-button
            type="danger"
            @click="updateExchangeStatus"
            v-loading="loading.reject"
        >
        Reject
      </el-button>
        <el-button
            type="secondary"
            @click="verifyTransaction"
            v-loading="loading.aml"
            :disabled="isAmlDisabled"
        >
        Check
      </el-button>
      <el-button
          type="primary"
          @click="updateExchangeDetail"
          v-loading="loading.update"
          :disabled="isAmlNeedCheck "
      >
         Change
      </el-button>
    </span>
    </template>
  </el-dialog>
</template>

<script>

const PAYMENT_REJECTED = 8;

export default {
  name: "AddTxHash",
  methods: {
    verifyTransaction() {
      this.loading.aml = true;
      this.$api.transactions
          .verify(this.form.txid, this.params.exchangeId)
          .then((response) => {
            this.risk = response.data.risk;
            this.aml.checked = true;
          })
          .catch(error => this.$message.error(error.message))
          .finally(() => this.loading.aml = false)
    },
    updateExchangeStatus() {
      this.loading.reject = true;
      this.$api.exchanges
          .update(this.params.exchangeId, {
            status: PAYMENT_REJECTED
          })
          .finally(() => this.loading.reject = false);
    },
    updateExchangeDetail() {
      this.loading.update = true;
      this.$api.exchanges.details
          .update(this.params.exchangeId, this.params.detailId, {
            txid: this.form.txid
          })
          .then(response => {
            this.visible = false;
            this.$emit('updated', response.data)
          })
          .finally(() => this.loading.update = false)
    },
    show(params) {
      this.params = {
        detailId: params.detailId,
        exchangeId: params.exchangeId,
        isFiat: params.isFiat,
      };

      this.aml.enabled = ! params.isFiat;
      this.form.txid = params.txId;
      this.visible = true;
    },
    hide() {
      this.visible = false;
      this.aml = {
        enabled: true,
        checked: false,
      }
      this.form.txid = false;
    },
  },
  computed: {
    isAmlChecked() {
      return this.aml.checked;
    },
    isAmlNeedCheck() {
      return this.aml.enabled;
    },
    isAmlDisabled() {
      return ! this.aml.enabled;
    }
  },
  data() {
    return {
      aml: {
        enabled: true,
        checked: false,
      },
      risk: null,
      rejected: false,
      form: {
        txid: null,
      },
      params: {
        detailId: null,
        exchangeId: null,
        isFiat: false,
      },
      visible: false,
      loading: {
        update: false,
        aml: false,
      }
    }
  },
}
</script>

<style scoped>

</style>
