<template>
  <el-container>
    <el-row class="tac">
      <el-col :span="6">
        <sidebar/>
      </el-col>
    </el-row>
    <el-container class="is-vertical">
      <navbar/>
      <el-main>
        <router-view/>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Navbar from '@/components/Navbar';
import Sidebar from "@/components/Sidebar";

export default {
  name: 'DefaultLayout',
  components: {
    Sidebar,
    Navbar
  }
}
</script>