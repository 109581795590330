<template>
  <el-upload
      class="upload-demo"
      @change="change"
      @on-remove="remove"
      :auto-upload="false"
      :file-list="files"
  >
    <el-button size="small" type="primary">Click to upload</el-button>
  </el-upload>
</template>

<script>
export default {
  name: "UploadFile",
  props: {
    file: {
      type: Object,
    }
  },
  computed: {
    files() {
      if (this.model.name) {
        return [
          {name: this.model.name, url: this.model.url},
        ];
      }
      return [];

    }
  },
  methods: {
    change(file) {
      this.$emit('input', this.model = file.raw);
    },
    remove() {
      this.$emit('input', this.model = null);
    }
  },
  data() {
    return {
      model: this.file ? this.file : {},
    }
  }
}
</script>
