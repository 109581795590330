<template>
  <el-table
      :data="coins"
      v-loading="loading.fetch"
      style="width: 100%;"
  >
    <el-table-column
        prop="id"
        label="ID"
    />
    <el-table-column
        prop="icon"
        label="Icon"
    >
      <template v-slot="scope">
        <el-button :type="scope.row.fiat ? 'info' : 'primary'" size="mini" circle>
          <img style="height: 18px" :src="scope.row.icon_url"/>
        </el-button>
      </template>
    </el-table-column>
    <el-table-column
        prop="title"
        label="Title"
    />
    <el-table-column
        prop="created_at"
        label="Created At">
    </el-table-column>
    <el-table-column
        prop="updated_at"
        label="Updated At">
    </el-table-column>
    <el-table-column label="Actions" align="center">
      <template #header>
        <el-button
            type="primary"
            @click="showCoinCreate"
        >
          + Add
        </el-button>
      </template>
      <template v-slot="scope">
        <el-button type="primary" icon="el-icon-edit" @click="showCoinEdit(scope.row)" circle/>
        <el-popconfirm
            title="Are you sure to delete this?"
            @confirm="deleteCoin(scope.row)"
        >
          <template #reference>
            <el-button
                type="danger"
                icon="el-icon-delete"
                v-loading="loading.delete"
                circle
            />
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <pagination :pagination="pagination" @change="fetchCoins"/>
  <coin-action ref="coinAction" @updated="fetchCoins" @created="fetchCoins"/>
</template>

<script>
import Pagination from "@/components/Pagination";
import CoinAction from "@/views/Crypto/Coin/CoinAction";

export default {
  components: {
    CoinAction,
    Pagination
  },
  created() {
    this.fetchCoins();
  },
  methods: {
    fetchCoins(page = 1) {
      this.loading.fetch = true;
      this.$api.coins.get(page).then(response => {
        this.coins = response.data;
        this.pagination = response.meta;
        this.loading.fetch = false;
      });
    },
    showCoinCreate() {
      this.$refs.coinAction.show();
    },
    showCoinEdit(coin) {
      this.$refs.coinAction.show({
        coinId: coin.id,
      });
    },
    deleteCoin(coin) {
      this.loading.delete = true;
      this.$api.coins.destroy(coin.id).then(() => {
        this.fetchCoins();
      }).finally(() => {
        this.loading.delete = false;
      })
    },
  },
  data() {
    return {
      coins: [],
      pagination: {},
      loading: {
        fetch: false,
        delete: false,
      },
    }
  }
}
</script>
