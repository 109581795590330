<template>
  <el-header>
    <el-dropdown>
      <i class="el-icon-setting" style="cursor: pointer;margin-right: 15px"></i>
      <template #dropdown>
        <el-dropdown-menu>
          <el-dropdown-item @click="logout">Sign Out</el-dropdown-item>
        </el-dropdown-menu>
      </template>
    </el-dropdown>
    <span>Staff</span>
  </el-header>
</template>

<script>
export default {
  name: "NavBar",
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.push({ name: "Login" })
    }
  }

}
</script>

<style scoped>
.el-header {
  color: #333;
  line-height: 60px;
  background-color: #FFFFFF;
  text-align: right;
  font-size: 12px;
}
</style>
