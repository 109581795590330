import { createApp } from 'vue'
import { createStore } from "vuex";
import './sass/app.scss'

import ElementPlus from 'element-plus';

import App from './App.vue';
import router from './router'
import Api from "@/api";
import cookies from 'js-cookie'
import store from './store'

import DefaultLayout from './layouts/DefaultLayout.vue'
import AuthLayout from './layouts/AuthLayout.vue'

const appStore = createStore(store);
router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !store.state.token) {
      const token = cookies.get('x-access-token')
      if (token) {
        appStore.dispatch('setTokenFromCookie', { token })
      } else {
        return next({name:'Login'});
      }
    }
    next();
});



const instance = createApp(App)
    .use(router)
    .use(ElementPlus)
    .use(appStore);

instance.component('default-layout', DefaultLayout)
instance.component('auth-layout', AuthLayout)

instance.config.globalProperties.$api = new Api();
instance.mount('#app');
