export default $client => ({
    get(page) {
        return $client.get('identity', {
            params: {
                page
            }
        })
    },
    update(id, attributes) {
        return $client.patch('identity/' + id, attributes);
    },
});
