import cookies from 'js-cookie'

export default {
  state: {
    token: null,
    user: null,
    modal: {
      template: null,
      options: {}
    },
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token
    },
    REMOVE_TOKEN (state) {
      state.token = null
    },
  },

  actions: {
    setToken ({ commit }, { token, expiresIn }) {
     // axios.defaults.headers.common.Authorization = 'Bearer ' + token
      const expiryTime = new Date(new Date().getTime() + expiresIn * 1000)
      cookies.set('x-access-token', token, { expires: expiryTime })
      commit('SET_TOKEN', token)
    },

    async setTokenFromCookie ({ commit }, { token }) {
     // axios.defaults.headers.common.Authorization = 'Bearer ' + token
        commit('SET_TOKEN', token)
       /*  const userData = await axios.get('auth/me')
          .catch((errors) => {
            console.dir(errors)
            dispatch('logout')
        }) */
      //commit('SET_USER', userData.data)
      /* const contentData = await axios.get('content')
      .catch((errors) => {
        console.dir(errors)
        dispatch('logout')
      }) */
    }, 

    logout ({ commit }) {
        // axios.defaults.headers.common.Authorization = ''
        cookies.remove('x-access-token')
        commit('REMOVE_TOKEN')
    },
  }
}