<template>
  <div class="page">
    <form class="box" id="login-form">
      <div class="input-group email-input">
        <label>Email</label>
        <input class="form-control" type="text" v-model="email">
        <span class="invalid-feedback" v-if="emailError.length > 0">{{ emailError }}</span>
      </div>
      <div class="input-group">
        <label>Password</label>
        <input class="form-control" type="password" v-model="password">
        <span class="invalid-feedback" v-if="passwordError.length > 0">{{ passwordError }}</span>
      </div>
      <button v-if="!load" class="btn submit" @click.prevent="login">Login</button>
      <div v-else class="load" 
        v-loading="load"
        element-loading-text="Loading..."
       :element-loading-spinner="svg"
        element-loading-svg-view-box="-10, -10, 50, 50"
        element-loading-background="white">loading
      </div>
    </form>
  </div>
</template>

<script>

export default {
    name: "Login",
    components: {
    },
    data() {
        return {
            load: false,
            email: '',
            password: '',
            emailError: '',
            passwordError: ''
        }
    },
    methods: {
        initErrors () {
            this.emailError = ''
            this.passwordError = ''
        },
        login() {
            this.load = true
            const that = this
            this.initErrors()
            this.$api.auth.login({
                email: this.email,
                password: this.password,
            }).then(response => {
                console.log(response)
                if (response.error) {
                  for (let key in response.errors) {
                    if (key === 'email' && response.errors[key][0]) {  
                      that.emailError = response.errors[key][0]  
                    } else if (key === 'password' && response.errors[key][0]) {
                      that.passwordError = response.errors[key][0]
                    }
                  }
                  return
                } 
                 const { token, expiresIn } = response
                 console.log(token, expiresIn)
                 that.$store.dispatch('setToken', { token, expiresIn })
                 that.$router.push({ path: "/" })
              })
              .catch(function (error) {
                console.log(error);
              })
            this.load = false
        },
    }
}
</script>

<style lang="scss" scoped>
.page {
  .box {
    &#login-form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      margin: 2% auto 0;
      width: 100%;
      max-width: 30rem;
      background-color: #24292e;

      .logo {
        width: 100%;
        height: 100px;
        margin: 3rem 1rem;
        background-image: url('/img/KYC-PAY-logo.png');
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

       .email-input {
            margin-top: 60px !important; 
        }

      .input-group {
        padding: .25rem 3rem;
        margin: .25rem 0 .75rem;
        width: 100%;

        @media (max-width: 576px) {
          padding: .25rem 1.5rem;
        }

        label {
          color: rgba(255, 255, 255, 0.5);
          padding-left: .5rem;
        }

        .form-control {
          width: 100%;
          height: 3.5rem;
          color: #ffffff;
          background-color: #1c2126;
          padding: .5rem 1rem .5rem 1.5rem;
          margin-top: .25rem;
          border: 1px solid #2f363d;
          border-radius: .75rem;
          box-shadow: 0 11px 23px rgba(0,0,0,.02);
          outline: none;
        }

        // input:-webkit-autofill {
        //   border: 0;
        //   -webkit-text-fill-color: #ffffff;
        //   background-color: #1c2126 !important;
        //   transition: background-color 5000s ease-in-out 0s;
        // }

        // @-webkit-keyframes autofill {
        //   to {
        //     background-color: #1c2126;
        //   }
        // }

        // input:-webkit-autofill {
        //   -webkit-animation-name: autofill;
        //   -webkit-animation-fill-mode: both;
        // }

        .invalid-feedback {
          width: 100%;
          padding-left: .5rem;
          font-size: 12px;
          color: #dc3545;
        }
      }

      .submit,
      .load {
        display: inline-flex;
        min-width: 10rem;
        min-height: 3.5rem;
        padding: .5rem 2.5rem;
        margin: 1.75rem auto 3rem;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 1.25rem;
        font-weight: 600;
        background-color: #62cdac;
        border: none;
        border-radius: .75rem;
      }

      .load {
        width: 10rem;
        height: 3rem;

        svg {
          width: 100%;
          height: 100%;
          animation:spin 1s linear infinite;

          @keyframes spin {
            100% {
              transform:rotate(360deg);
            }
          }
        }
      }
    }
  }
}

.submit {
    cursor: pointer;
}
</style>
