<template>
  <el-card class="box-card">
    <template #header>
      <div class="card-header">
        <span>Other Settings</span>
      </div>
    </template>
    <el-form label-position="top" :model="form">
      <el-form-item label="Admin Password">
        <el-input class="password" maxlength="44" :min="6" v-model="form.password"></el-input>
        <span class="invalid-feedback" v-if="passwordError && passwordError.length > 0">{{ passwordError }}</span>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submit" v-loading="loading.update">Update</el-button>
      </el-form-item> 
    </el-form>
  </el-card>
</template>

<script>
import { ElNotification } from 'element-plus'

export default {
  name: "OtherSettings",
  methods: {
    fetchCoins() {
      this.$api.coins.getFiat().then(response => {
        this.coins = response.data;
      })
    },
    submit() {
      this.passwordError = null
      if (!this.form.password) {
        this.passwordError = 'Please, enter password'
        return
      } else if (this.form.password.length < 6) {
        this.passwordError = 'Password length must be more than 5 characters'
        return
      }
      this.loading.update = true;
      this.$api.settings.exchange.updatePassword(this.form.password)
          .then(function(res) {
            if(res.success) {
              ElNotification({
                title: 'Success',
                message: 'Password was updated successfuly',
                type: 'success',
              })
            }
          })
          .finally(() => this.loading.update = false);
    }
  },
  data() {
    return {
      settings: {},
      passwordError: null,
      loading: {
        update: false,
      },
      form: {
        password: null,
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 
.password {
    max-width: 20em;
}

.invalid-feedback {
  width: 100%;
  padding-left: .5rem;
  font-size: 12px;
  color: #dc3545;
}

</style>
