import { createRouter, createWebHashHistory } from 'vue-router'

import Dashboard from '../views/Dashboard.vue'

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        sidebar: {
            icon: 'el-icon-menu',
            title: 'Dashboard',
        },
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/exchanges',
        name: 'exchanges',
        sidebar: {
            icon: 'el-icon-refresh',
            title: 'Exchanges',
        },
        component: () => import(/* webpackChunkName: "exchanges" */ '../views/Exchange/Exchange.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/crypto',
        name: 'crypto',
        sidebar: {
            icon: 'el-icon-money',
            title: 'Crypto',
        },
        component: () => import(/* webpackChunkName: "crypto" */ '../views/Crypto/Crypto.vue'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'coins',
                name: 'coins',
                sidebar: {
                    title: 'Coins'
                },
                component: () => import(/* webpackChunkName: "coins" */ '../views/Crypto/Coin/Coin.vue'),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: 'coin-pairs',
                name: 'coinPairs',
                sidebar: {
                    title: 'Pairs'
                },
                component: () => import(/* webpackChunkName: "coin-pairs" */ '../views/Crypto/CoinPair/CoinPair.vue'),
                meta: {
                    requiresAuth: true,
                }
            },
        ]
    },
    {
        path: '/identity-verifications',
        name: 'identityVerifications',
        sidebar: {
            icon: 'el-icon-coordinate',
            title: 'Identity Verifications',
        },
        component: () => import(/* webpackChunkName: "identity-verifications" */ '../views/IdentityVerification/IdentityVerification.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/payment-wallets',
        name: 'paymentWallets',
        sidebar: {
            icon: 'el-icon-wallet',
            title: 'Payment wallets',
        },
        component: () => import(/* webpackChunkName: "payment-wallets" */ '../views/PaymentWallet/PaymentWallet.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/users',
        name: 'users',
        sidebar: {
            icon: 'el-icon-user',
            title: 'Users',
        },
        component: () => import(/* webpackChunkName: "users" */ '../views/User/User.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/feedback',
        name: 'feedback',
        sidebar: {
            icon: 'el-icon-chat-line-square',
            title: 'Feedback',
        },
        component: () => import(/* webpackChunkName: "users" */ '../views/Feedback.vue'),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/settings',
        name: 'settings',
        sidebar: {
            icon: 'el-icon-setting',
            title: 'Settings',
        },
        component: () => import(/* webpackChunkName: "exchange-settings" */ '../views/Settings/Settings'),
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'exchange',
                name: 'exchangeSettings',
                sidebar: {
                    icon: 'el-icon-setting',
                    title: 'Exchange',
                },
                component: () => import(/* webpackChunkName: "exchange-settings" */ '../views/Settings/ExchangeSettings'),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: 'payment-gateways',
                name: 'paymentGateways',
                sidebar: {
                    icon: 'el-icon-setting',
                    title: 'Payment gateways',
                },
                component: () => import('../views/Settings/PaymentGateways'),
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: 'other-settings',
                name: 'otherSettings',
                sidebar: {
                    icon: 'el-icon-setting',
                    title: 'Other',
                },
                component: () => import(/* webpackChunkName: "exchange-settings" */ '../views/Settings/OtherSettings'),
                meta: {
                    requiresAuth: true,
                }
            }
        ]
    },
    {
      path: '/exchange-rates',
      name: 'exchange-rates',
      sidebar: {
        icon: 'el-icon-setting',
        title: 'Exchange Rates',
      },
      component: () => import('../views/ExchangeRates'),
      meta: {
        requiresAuth: true
      },
    }
]

const router = createRouter({
    base: '/admin',
    history: createWebHashHistory(),
    routes
})

export default router
