<template>
  <el-table
      :data="coinPairs"
      v-loading="loading.fetch"
      style="width: 100%;"
  >
    <el-table-column
        prop="id"
        label="ID"
    />
    <el-table-column label="Base">
      <template v-slot="scope">
        <el-button :type="scope.row.base.fiat ? 'info' : 'primary'" size="mini" circle>
          <img style="height: 18px" :src=" scope.row.base.icon_url"/>
        </el-button>
        {{ scope.row.base.title }}
      </template>
    </el-table-column>
    <el-table-column label="Quote">
      <template v-slot="scope">
        <el-button :type="scope.row.quote.fiat ? 'info' : 'primary'" size="mini" circle>
          <img style="height: 18px" :src=" scope.row.quote.icon_url"/>
        </el-button>
        {{ scope.row.quote.title }}
      </template>
    </el-table-column>
    <el-table-column
        label="Stock Exchange"
    >
      <template v-slot="scope">
        {{ scope.row.stock_exchange ? scope.row.stock_exchange.title : 'Default' }}
      </template>
    </el-table-column>
    <el-table-column
        prop="rate.value"
        label="Rate"
    >
    </el-table-column>
    <el-table-column
        prop="created_at"
        label="Created At">
    </el-table-column>
    <el-table-column
        prop="updated_at"
        label="Updated At">
    </el-table-column>
    <el-table-column label="Actions" align="center">
      <template #header>
        <el-button
            type="primary"
            @click="showCoinPairCreate"
        >
          + Add
        </el-button>
      </template>
      <template v-slot="scope">
        <el-button type="primary" icon="el-icon-edit" @click="showCoinPairEdit(scope.row)" circle/>
        <el-popconfirm
            title="Are you sure to delete this?"
            @confirm="deleteCoinPair(scope.row)"
        >
          <template #reference>
            <el-button
                type="danger"
                icon="el-icon-delete"
                v-loading="loading.delete"
                circle
            />
          </template>
        </el-popconfirm>
      </template>
    </el-table-column>
  </el-table>
  <pagination :pagination="pagination" @change="this.fetchCoinPairs"/>
  <coin-pair-action ref="coinPairAction" @updated="fetchCoinPairs" @created="fetchCoinPairs"/>
</template>

<script>
import Pagination from "@/components/Pagination";
import CoinPairAction from "@/views/Crypto/CoinPair/CoinPairAction";

export default {
  components: {
    CoinPairAction,
    Pagination
  },
  created() {
    this.fetchCoinPairs();
  },
  methods: {
    fetchCoinPairs(page = 1) {
      this.loading.fetch = true;
      this.$api.coinPairs.get(page)
          .then(response => {
            this.coinPairs = response.data;
            this.pagination = response.meta;
          })
          .finally(() => this.loading.fetch = false)
    },
    showCoinPairCreate() {
      this.$refs.coinPairAction.show();
    },
    showCoinPairEdit(coinPair) {
      this.$refs.coinPairAction.show({
        id: coinPair.id
      })
    },
    deleteCoinPair(coinPair) {
      this.loading.delete = true;
      this.$api.coinPairs.destroy(coinPair.id)
          .then(() => {
            this.fetchCoinPairs();
          })
          .finally(() => this.loading.delete = false);
    }

  },
  data() {
    return {
      coinPairs: [],
      pagination: {},
      loading: {
        fetch: false,
        delete: false,
      },
    }
  }
}
</script>
