<template>
  <el-table
    :data="items"
    v-loading="loading"
    class="tbl"
  >
    <el-table-column
      prop="id"
      label="ID"
      width="50"
    />
    <el-table-column
      prop="phone"
      label="Phone"
    />
    <el-table-column
      prop="email"
      label="E-Mail"
    />
    <el-table-column
      prop="comment"
      label="Comment"
    />
    <el-table-column
      prop="created_at"
      label="Created At"
    />
    <el-table-column
      prop="updated_at"
      label="Updated At"
    />
  </el-table>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      loading: false,
    }
  },
  created() {
    this.fetchItems()
  },
  methods: {
    fetchItems () {
      this.loading = true
      this.$api.feedback.get()
        .then(response => {
          this.items = response.data;
          this.loading = false;
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.tbl {
  width: 100%;
}
</style>
