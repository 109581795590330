<script setup>
import { ref } from "vue";

import api from '../api'

/* Utils */
const pending = ref(false);
const page = ref(1)

/* Data */
const rates = ref([]);

const data = await api.paymentWallets.get(page)
console.debug(data)
</script>

<template>
  <div>
    <el-table :data="rates" v-loading="pending">
      <el-table-column label="#" />
      <el-table-column prop="id" label="Fiat" />
      <el-table-column prop="id" label="Crypto" />
      <el-table-column prop="id" label="Rate" />
      <el-table-column prop="value" label="Value" />
      <el-table-column prop="created_at" label="Updated At"></el-table-column>
    </el-table>
  </div>
</template>
