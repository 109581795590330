import axios from 'axios';

const client = axios.create({
  baseURL: '/api/staff/',
});

client.interceptors.response.use(success => success.data, error => {
  const response = error.response;
  return Promise.reject(response.data);
});

export default client;
