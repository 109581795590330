<template>
  <div id="main">
    <component :is="layout">
      <router-view/>
    </component>
  </div>
</template>

<script>


export default {
  computed: {
    layout() {
      return (this.$route.meta.requiresAuth) ? 'default-layout' : 'auth-layout'
    }
  },
};
</script>

<style>
body {
  background-color: #e7e7e7;
}

.el-aside {
  color: #333;
}
</style>
