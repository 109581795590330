<template>
  <div class="auth-wrapper">
    <router-view/>
  </div>
</template>

<script>
export default {
	name: 'AuthLayout',
    components: {}
}
</script>

<style lang="scss" scoped>
.auth-wrapper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
</style>